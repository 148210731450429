import React, { FC } from 'react';

import { ILandingBannerComponentProps } from 'components/LandingBanner/models';
import 'components/LandingBanner/LandingBanner.scss';
import GatsbyImage from 'components/common/GatsbyImage';

const LandingBanner: FC<ILandingBannerComponentProps> = ({
  image,
  mobileImage,
  productImage,
  heading,
  subHeading,
  finePrint,
  content,
}) => (
  <div className="landing-banner">
    <div className="landing-banner__content">
      <div className="landing-banner__text">
        <h1 className="home-banner__text-title">
          <div className="home-banner__text-line">{heading}</div>
          <div className="home-banner__text-line home-banner__text-line--alt">{subHeading}</div>
        </h1>
        <div className="landing-banner__moretext" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <GatsbyImage
        isLazyLoading={false}
        className="landing-banner__product-image"
        fluid={productImage}
        alt={image.altText}
        objectPosition="50% 100%"
        useFocalPoint
        objectFit="contain"
      />
      <div className="landing-banner__fineprint">{finePrint}</div>
    </div>

    <GatsbyImage
      isLazyLoading={false}
      className="landing-banner__image"
      fluid={image}
      alt="image alt"
      useFocalPoint
      objectFit="cover"
      style={{ position: 'absolute' }}
    />
    <GatsbyImage
      isLazyLoading={false}
      className="landing-banner__image--mobile"
      fluid={mobileImage}
      alt="image alt"
      objectPosition="50% 100%"
      useFocalPoint
      objectFit="contain"
      style={{ position: 'absolute' }}
    />
  </div>
);

export default LandingBanner;
