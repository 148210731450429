import React, { FC } from 'react';

import { ILandingDividerComponentProps } from 'components/LandingDivider/models';
import 'components/LandingDivider/LandingDivider.scss';
import GatsbyImage from 'components/common/GatsbyImage';

const LandingDivider: FC<ILandingDividerComponentProps> = ({ image }) => (
  <div className="landing-divider">
    <GatsbyImage
      isLazyLoading={false}
      fluid={image}
      alt={image.altText}
      objectPosition="50% 100%"
      useFocalPoint
    />
    <div className="landing-divider__curve" />
  </div>
);

export default LandingDivider;
