import React, { FC } from 'react';
import classNames from 'classnames';

import { ITitleBlockProps } from './models';
import './TitleBlock.scss';

const TitleBlock: FC<ITitleBlockProps> = ({ titleBlock, className }) => {
  const {
    properties: { title, text, subText, textSecond },
  } = titleBlock[0];

  return (
    <div className={classNames('title-block__wrapper', className)}>
      <h1 className="title-block__title">{title}</h1>
      <p className="title-block__text">{text}</p>
      <p className="title-block__text-second">{textSecond}</p>
      <p className="title-block__sub-text">{subText}</p>
    </div>
  );
};

export default TitleBlock;
