import React, { FC, useEffect } from 'react';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import './LandingFeaturesComparison.scss';
import { ILandingFeaturesComparisonComponentProps } from "./models"

const LandingFeaturesComparison: FC<ILandingFeaturesComparisonComponentProps> = ({
  heading,
  leftFeatures,
  rightFeatures,
  leftPackshot,
  rightPackshot,
  leftProductLogo,
  rightProductLogo,
}) => {
  useEffect(() => {
    const setEqualHeights = () => {
      const isDesktop = window.innerWidth >= 1364; // Adjust the width as per your breakpoint
      const leftFeatures = document.querySelectorAll(
        '.features-comparison__features--left .features-comparison__feature'
      );
      const rightFeatures = document.querySelectorAll(
        '.features-comparison__features--right .features-comparison__feature'
      );

      if (leftFeatures.length !== rightFeatures.length) {
        console.warn('Left and right features do not match in number.');
        return;
      }

      // Reset heights before recalculating
      leftFeatures.forEach((feature) => ((feature as HTMLElement).style.height = 'auto'));
      rightFeatures.forEach((feature) => ((feature as HTMLElement).style.height = 'auto'));

      if (isDesktop) {
        // Handle desktop view (two per row)
        for (let i = 0; i < leftFeatures.length; i += 2) {
          const leftFeature1 = leftFeatures[i];
          const rightFeature1 = rightFeatures[i];
          const leftFeature2 = leftFeatures[i + 1];
          const rightFeature2 = rightFeatures[i + 1];

          const maxHeight1 = Math.max(leftFeature1.clientHeight, rightFeature1.clientHeight);
          const maxHeight2 = Math.max(
            leftFeature2?.clientHeight || 0,
            rightFeature2?.clientHeight || 0
          );

          (leftFeature1 as HTMLElement).style.height = `${maxHeight1}px`;
          (rightFeature1 as HTMLElement).style.height = `${maxHeight1}px`;

          if (leftFeature2 && rightFeature2) {
            (leftFeature2 as HTMLElement).style.height = `${maxHeight2}px`;
            (rightFeature2 as HTMLElement).style.height = `${maxHeight2}px`;
          }
        }
      } else {
        // Handle mobile view (one per row)
        for (let i = 0; i < leftFeatures.length; i++) {
          const leftFeature = leftFeatures[i];
          const rightFeature = rightFeatures[i];
          const maxHeight = Math.max(leftFeature.clientHeight, rightFeature.clientHeight);

          (leftFeature as HTMLElement).style.height = `${maxHeight}px`;
          (rightFeature as HTMLElement).style.height = `${maxHeight}px`;
        }
      }
    };

    setEqualHeights();
    window.addEventListener('resize', setEqualHeights);

    return () => {
      window.removeEventListener('resize', setEqualHeights);
    };
  }, []);

  return (
    <div className="features-comparison">
      <div className="features-comparison__container">
        <h2 className="features-comparison__header">{heading}</h2>
        <div className="features-comparison__packshots" />
        <div className="features-comparison__products">
        <div className="features-comparison__divider"/>
          <div className="features-comparison__product">
            <div className="features-comparison__packshot-container">
              <div className="features-comparison__packshot">
                <GatsbyImage fluid={leftPackshot} alt={leftPackshot.altText} />
              </div>
            </div>
            <div className="features-comparison__product-logo-container">
              <div className="features-comparison__product-logo">
                <GatsbyImage fluid={leftProductLogo} alt={leftProductLogo.altText} />
              </div>
            </div>
            <div className="features-comparison__features features-comparison__features--left">
              {leftFeatures.map((feature, index) => (
                <div className="features-comparison__feature" key={index}>
                  <img
                    className="features-comparison__feature-icon"
                    src={feature.properties.image?.fallbackUrl}
                    alt={feature.properties.image?.altText}
                  />
                  <div className="features-comparison__feature-heading">
                    {feature.properties.title}
                  </div>
                  <div className="features-comparison__feature-text">{feature.properties.text}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="features-comparison__product">
            <div className="features-comparison__packshot-container">
              <div className="features-comparison__packshot features-comparison__packshot--right">
                <GatsbyImage fluid={rightPackshot} alt={rightPackshot.altText} />
              </div>
            </div>
            <div className="features-comparison__product-logo-container features-comparison__product-logo-container--right">
              <div className="features-comparison__product-logo features-comparison__product-logo--right">
                <GatsbyImage fluid={rightProductLogo} alt={rightProductLogo.altText} />
              </div>
            </div>
            <div className="features-comparison__features features-comparison__features--right">
              {rightFeatures.map((feature, index) => (
                <div className="features-comparison__feature" key={index}>
                  <img
                    className="features-comparison__feature-icon features-comparison__feature-icon--right"
                    src={feature.properties.image?.fallbackUrl}
                    alt={feature.properties.image?.altText}
                  />
                  <div className="features-comparison__feature-heading">
                    {feature.properties.title}
                  </div>
                  <div className="features-comparison__feature-text">{feature.properties.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFeaturesComparison;
