import React, { FC } from 'react';

import { Nav, Navbar } from 'react-bootstrap';
import './LandingHeader.scss';
import { Link } from 'gatsby';
import { ILandingHeaderComponentProps } from './models';

const LandingHeader: FC<ILandingHeaderComponentProps> = ({ heading, menuLinks, link }) => (
  <div className="landing-header">
    <Link className="landing-header__logo" to="/">
      {heading}
    </Link>
    <Navbar expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto landing-header__main-items">
          {menuLinks?.map((navItem, idx) => (
            <Nav.Link
              key={idx}
              className="landing-header__nav-item"
              href={navItem.url ? navItem.url : `${navItem.queryString}` ? navItem.queryString : ''}
            >
              {navItem.name}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <a
      href={link[0].url}
      target={link[0].target}
      rel="noopener"
      className="landing-header__buy-now"
    >
      <span className="name gs-btn--landing-page">{link[0].name}</span>
      <span className="icon">
        <img src="/images/shop.svg" width="21" height="23" alt={link[0].name} />
      </span>
    </a>
  </div>
);

export default LandingHeader;
