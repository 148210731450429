import React, { FC, useState, useCallback } from 'react';

import { ILandingVideoComponentProps } from 'components/LandingVideo/models';
import 'components/LandingVideo/LandingVideo.scss';
import GatsbyImage from 'components/common/GatsbyImage';
import Button from 'components/common/Button';

const LandingVideo: FC<ILandingVideoComponentProps> = ({ image, youtubeVideoId, heading }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className="landing-video">
      <div className="container-fluid">
        <h3 className="lp-heading">{heading}</h3>
        <div className="youtube-container">
          <div className="youtube-player">
            <div>
              {isOpen ? (
                <iframe
                  className="youtube-iframe"
                  width="600"
                  height="338"
                  src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&color=white&autohide=2&modestbranding=1&border=0&wmode=opaque&enablejsapi=1&showinfo=0&rel=0`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ) : (
                <div className="youtube-thumbnail">
                  <GatsbyImage
                    className="youtube-thumbnail__image"
                    alt={image.altText}
                    fluid={image}
                  />
                  <Button classes="youtube-play-btn" variant="link" onClick={toggleModal} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingVideo;
