import React, { FC } from 'react';

import { ILandingBannerIndulgenceComponentProps } from 'components/LandingBannerIndulgence/models';
import 'components/LandingBannerIndulgence/LandingBannerIndulgence.scss';
import GatsbyImage from 'components/common/GatsbyImage';

const LandingBannerIndulgence: FC<ILandingBannerIndulgenceComponentProps> = ({
  image,
  mobileImage,
  productImage,
  heading,
  heading2,
  subHeading,
  subHeading2,
  finePrint,
  content,
}) => (
  <div className="landing-banner landing-banner--indulgence">
    <div className="landing-banner__image">
      <GatsbyImage
        isLazyLoading={false}
        fluid={image}
        alt={image.altText}
        objectFit="cover"
        objectPosition="100% 0%"
      />
    </div>
    <div className="landing-banner__content">
      <div className="landing-banner__text">
        <h1 className="home-banner__text-title">
          <div className="home-banner__text-line">{heading}</div>
          <div className="home-banner__text-line">{heading2}</div>
          <div className="home-banner__text-line home-banner__text-line--alt">{subHeading}</div>
          <div className="home-banner__text-line home-banner__text-line--alt">{subHeading2}</div>
        </h1>
      </div>
      <div className="landing-banner__moretext" dangerouslySetInnerHTML={{ __html: content }} />
      <GatsbyImage
        isLazyLoading={false}
        className="landing-banner__product-image"
        fluid={productImage}
        alt="image alt"
        objectPosition="50% 100%"
        useFocalPoint
        objectFit="contain"
      />
      <div className="landing-banner__fineprint">{finePrint}</div>
    </div>

    <GatsbyImage
      isLazyLoading={false}
      className="landing-banner__image--mobile"
      fluid={mobileImage}
      alt="image alt"
      objectPosition="50% 100%"
      useFocalPoint
      objectFit="contain"
      style={{ position: 'absolute' }}
    />
  </div>
);

export default LandingBannerIndulgence;
