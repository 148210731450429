import React, { FC, useEffect } from 'react';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import './LandingBenefitsComparison.scss';
import { Link } from 'gatsby';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { ILandingBenefitsComparisonComponentProps } from './models';

const LandingBenefitsComparison: FC<ILandingBenefitsComparisonComponentProps> = ({
  heading,
  leftFeatures,
  rightFeatures,
  leftLink,
  rightLink,
  leftPackshot,
  rightPackshot,
  leftProductLogo,
  rightProductLogo,
  additional,
}) => {
  useEffect(() => {
    const setEqualHeights = () => {
      const isDesktop = window.innerWidth >= 1364;
      const leftBenefits = document.querySelectorAll(
        '.benefits-comparison__benefits--left .benefits-comparison__benefit'
      );
      const rightBenefits = document.querySelectorAll(
        '.benefits-comparison__benefits--right .benefits-comparison__benefit'
      );

      const len = Math.min(leftBenefits.length, rightBenefits.length);


      leftBenefits.forEach((benefit) => ((benefit as HTMLElement).style.height = 'auto'));
      rightBenefits.forEach((benefit) => ((benefit as HTMLElement).style.height = 'auto'));

      if (isDesktop) {
        for (let i = 0; i < len; i += 2) {
          const leftBenefit1 = leftBenefits[i];
          const rightBenefit1 = rightBenefits[i];
          const leftBenefit2 = leftBenefits[i + 1];
          const rightBenefit2 = rightBenefits[i + 1];

          const maxHeight1 = Math.max(leftBenefit1.clientHeight, rightBenefit1.clientHeight);
          const maxHeight2 = Math.max(
            leftBenefit2?.clientHeight || 0,
            rightBenefit2?.clientHeight || 0
          );

          (leftBenefit1 as HTMLElement).style.height = `${maxHeight1}px`;
          (rightBenefit1 as HTMLElement).style.height = `${maxHeight1}px`;

          if (leftBenefit2 && rightBenefit2) {
            (leftBenefit2 as HTMLElement).style.height = `${maxHeight2}px`;
            (rightBenefit2 as HTMLElement).style.height = `${maxHeight2}px`;
          }
        }
      } else {
        for (let i = 0; i < len; i++) {
          const leftBenefit = leftBenefits[i];
          const rightBenefit = rightBenefits[i];
          const maxHeight = Math.max(leftBenefit.clientHeight, rightBenefit.clientHeight);

          (leftBenefit as HTMLElement).style.height = `${maxHeight}px`;
          (rightBenefit as HTMLElement).style.height = `${maxHeight}px`;
        }
      }
    };

    setEqualHeights();
    window.addEventListener('resize', setEqualHeights);

    return () => {
      window.removeEventListener('resize', setEqualHeights);
    };
  }, []);

  return (
    <div className="benefits-comparison">
      <div className="benefits-comparison__container">
        <h2 className="benefits-comparison__header">{heading}</h2>
        <div className="benefits-comparison__products">
          <div className="benefits-comparison__divider"/>
          <div className="benefits-comparison__product">
            <div className="benefits-comparison__packshot-container">
              <div className="benefits-comparison__packshot">
                <GatsbyImage fluid={leftPackshot} alt={leftPackshot.altText} />
              </div>
            </div>
            <div className="benefits-comparison__product-logo-container">
              <div className="benefits-comparison__product-logo">
                <GatsbyImage fluid={leftProductLogo} alt={leftProductLogo.altText} />
              </div>
            </div>
            <div className="benefits-comparison__benefits benefits-comparison__benefits--left">
              {leftFeatures.map((feature, index) => (
                <div className="benefits-comparison__benefit" key={index}>
                  <img
                    className="benefits-comparison__feature-icon"
                    src={feature.properties.image?.fallbackUrl}
                    alt={feature.properties.image?.altText}
                  />
                  <div className="benefits-comparison__benefit-heading">
                    {feature.properties.title}
                  </div>
                  <div className="benefits-comparison__benefit-text">{feature.properties.text}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="benefits-comparison__product">
            <div className="benefits-comparison__packshot-container">
              <div className="benefits-comparison__packshot benefits-comparison__packshot--right">
                <GatsbyImage fluid={rightPackshot} alt={rightPackshot.altText} />
              </div>
            </div>
            <div className="benefits-comparison__product-logo-container benefits-comparison__product-logo-container--right">
              <div className="benefits-comparison__product-logo benefits-comparison__product-logo--right">
                <GatsbyImage fluid={rightProductLogo} alt={rightProductLogo.altText} />
              </div>
            </div>
            <div className="benefits-comparison__benefits benefits-comparison__benefits--right">
              {rightFeatures.map((feature, index) => (
                <div className="benefits-comparison__benefit" key={index}>
                  <img
                    className="benefits-comparison__feature-icon benefits-comparison__feature-icon--right"
                    src={feature.properties.image?.fallbackUrl}
                    alt={feature.properties.image?.altText}
                  />
                  <div className="benefits-comparison__benefit-heading">
                    {feature.properties.title}
                  </div>
                  <div className="benefits-comparison__benefit-text">{feature.properties.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="benefits-comparison__buy-now-row">
          <div className="benefits-comparison__divider" />
          <div className="benefits-comparison__buy-now-block">
            <div className="benefits-comparison__additional">
              <DangerouslySetInnerHtml html={additional} />
            </div>
            <div className="benefits-comparison__buy-now-container">
              <Link className="benefits-comparison__buy-now-button" target={leftLink[0].target} to={leftLink[0].url}>
                <div className="benefits-comparison__buy-now-button-content">
                  {leftLink[0].name}
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 2.00281L5.74264 6.24545L1.5 10.4881"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
          <div className="benefits-comparison__buy-now-block">
            <div className="benefits-comparison__buy-now-container">
              <Link
                className="benefits-comparison__buy-now-button benefits-comparison__buy-now-button--right"
                target={rightLink[0].target} to={rightLink[0].url}
              >
                <div className="benefits-comparison__buy-now-button-content">
                  {rightLink[0].name}
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 2.00281L5.74264 6.24545L1.5 10.4881"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBenefitsComparison;
