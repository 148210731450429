import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import './LandingTextBlock.scss';
import { ILandingTextBlockComponentProps } from './models';

const LandingTextBlock: FC<ILandingTextBlockComponentProps> = ({ content }) => {
  return (
    <Container>
      <div className="landing-text-block">
        <DangerouslySetInnerHtml html={content} />
      </div>
    </Container>
  );
};

export default LandingTextBlock;
