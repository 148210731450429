import React, { FC } from 'react';

import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';

import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import './LandingBannerComparison.scss';
import { ILandingBannerComparisonComponentProps } from './models';

const LandingBannerComparison: FC<ILandingBannerComparisonComponentProps> = ({
  heading,
  headingMobile,
  leftOptionText,
  rightOptionText,
  optionSeparatorText,
  leftTryText,
  rightTryText,
  leftProductLogo,
  rightProductLogo,
  leftProductDescriptionText,
  rightProductDescriptionText,
  leftPackshot,
  rightPackshot,
  leftPackshotMobile,
  rightPackshotMobile,
  image,
}) => {
  const { isLargeDesktop } = useScreenRecognition();

  const displayedHeading = isLargeDesktop ? heading : headingMobile;

  const leftPackshotImage = isLargeDesktop ? leftPackshot : leftPackshotMobile;
  const rightPackshotImage = isLargeDesktop ? rightPackshot : rightPackshotMobile;

  return (
    <div className="comparison-banner">
      <div className="comparison-banner__container">
        <div className="comparison-banner__gradient-image">
          <GatsbyImage fluid={image} alt={image.altText} />
        </div>
        <div className="comparison-banner__left-bottom-bg" />
        <div className="comparison-banner__right-bottom-bg" />
        <DangerouslySetInnerHtml html={displayedHeading} />
        <div className="comparison-banner__options">
          <div className="comparison-banner__option comparison-banner__option--left">
            <div className="comparison-banner__option-button comparison-banner__option-button--left">
              <span>{leftOptionText}</span>
            </div>
          </div>
          <div className="comparison-banner__option-button-separator">{optionSeparatorText}</div>
          <div className="comparison-banner__option comparison-banner__option--right">
            <div className="comparison-banner__option-button comparison-banner__option-button--right">
              <span>{rightOptionText}</span>
            </div>
          </div>
        </div>
        <div className="comparison-banner__products">
          <div className="comparison-banner__product">
            <div className="comparison-banner__trytext">{leftTryText}</div>
            <div className="comparison-banner__product-logo">
              <GatsbyImage fluid={leftProductLogo} alt={leftProductLogo.altText} useFocalPoint />
            </div>
            <div className="comparison-banner__product-description-text">
              <DangerouslySetInnerHtml html={leftProductDescriptionText} />
            </div>
            <div className="comparison-banner__packshot">
              <GatsbyImage fluid={leftPackshotImage} alt={leftPackshotImage.altText} />
            </div>
          </div>
          <div className="comparison-banner__product">
            <div className="comparison-banner__trytext comparison-banner__trytext--right">
              {rightTryText}
            </div>
            <div className="comparison-banner__product-logo comparison-banner__product-logo--right">
              <GatsbyImage fluid={rightProductLogo} alt={rightProductLogo.altText} useFocalPoint />
            </div>
            <div className="comparison-banner__product-description-text comparison-banner__product-description-text--right">
              <DangerouslySetInnerHtml html={rightProductDescriptionText} />
            </div>
            <div className="comparison-banner__packshot comparison-banner__packshot--right">
              <GatsbyImage fluid={rightPackshotImage} alt={rightPackshotImage.altText} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBannerComparison;
