import React, { FC } from 'react';

import { ILandingFaqComponentProps } from 'components/LandingFaq/models';
import 'components/LandingFaq/LandingFaq.scss';
import Button from 'components/common/Button';
import Accordion from 'react-tiny-accordion';
import 'bootstrap/dist/css/bootstrap.css';
import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';

const LandingFaq: FC<ILandingFaqComponentProps> = ({
  questions,
  heading,
  subHeading,
  image,
  finePrint,
}) => (
  <div className="landing-faq">
    <div className="container-fluid landing-faq__container">
      <div className="landing-faq__graphic">
        <GatsbyImage
          isLazyLoading
          fluid={image}
          alt="image alt"
          objectPosition="50% 100%"
          useFocalPoint
          objectFit="contain"
          className="landing-faq__image"
        />
        <h3 className="landing-faq__title">{subHeading}</h3>
      </div>
      <div className="landing-faq__questions">
        <h3 className="landing-faq__heading">{heading}</h3>
        <Accordion className="gs-accordion" transitionDuration="300">
          {questions?.length
            ? questions.map(({ properties: { answer, question } }) => (
                <div
                  className="gs-accordion__item"
                  key={question}
                  data-header={
                    <Button variant="icon" classes="gs-accordion__question">
                      <DangerouslySetInnerHtml html={question} />
                      <IconCustom icon="chevron-down" />
                    </Button>
                  }
                >
                  <DangerouslySetInnerHtml html={answer} />
                </div>
              ))
            : null}
        </Accordion>
      </div>
      <DangerouslySetInnerHtml className="landing-faq__fine-print" html={finePrint} />
    </div>
  </div>
);

export default LandingFaq;
