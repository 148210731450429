import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import GatsbyImage from 'components/common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import TitleBlock from './TitleBlock';

import { ILandingBannerSecondProps } from './models';
import './LandingBannerSecond.scss';

const LandingBannerSecond: FC<ILandingBannerSecondProps> = (props) => {
  const { titleBlock, backgroundImage, brandTitle, bannerProductImage, text, rightImage } = props;

  const {
    properties: { altText, image },
  } = backgroundImage[0];
  const {
    properties: { altText: brandAltText, image: brandImage },
  } = brandTitle[0];
  const {
    properties: { altText: bannerAltText, image: bannerImage },
  } = bannerProductImage[0];
  const {
    properties: { altText: rightAltText, image: righTImage },
  } = rightImage[0];

  const { isMobile } = useScreenRecognition();

  return (
    <div data-test="LandingBannerSecond" className="landing-banner-second__wrapper">
      {isMobile || !image ? (
        <div className="landing-banner-second__background--color" />
      ) : (
        <GatsbyImage
          isLazyLoading={false}
          fluid={image}
          alt={altText}
          useFocalPoint
          className="landing-banner-second__background"
        />
      )}
      <Container fluid className="landing-banner-second__container">
        <GatsbyImage
          isLazyLoading={false}
          fluid={brandImage}
          alt={brandAltText}
          useFocalPoint
          objectFit="cover"
          className="landing-banner-second__brand-title"
        />
        <TitleBlock titleBlock={titleBlock} className="landing-banner-second__title-block" />
        {isMobile ? (
          <GatsbyImage
            isLazyLoading={false}
            fluid={bannerImage}
            alt={bannerAltText}
            useFocalPoint
            objectFit="cover"
            className="landing-banner-second__brand-item--mobile"
          />
        ) : null}
        <div className="landing-banner-second__brand-text">{text}</div>
      </Container>
      {!isMobile ? (
        <GatsbyImage
          isLazyLoading={false}
          fluid={bannerImage}
          alt={bannerAltText}
          useFocalPoint
          objectFit="cover"
          className="landing-banner-second__brand-item"
        />
      ) : null}

      <GatsbyImage
        isLazyLoading={false}
        fluid={righTImage}
        alt={rightAltText}
        useFocalPoint
        objectFit="cover"
        className="landing-banner-second__right"
      />
    </div>
  );
};

export default LandingBannerSecond;
