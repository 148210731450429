import React, { FC } from 'react';
import './LandingRelatedProducts.scss';
import { graphql, useStaticQuery } from 'gatsby';
import ProductCard from 'components/ProductCard';
import { ILandingRelatedProductsComponentProps } from './models';

const LandingRelatedProducts: FC<ILandingRelatedProductsComponentProps> = ({
  heading,
  featuredProducts,
}) => {
  const { allProduct } = useStaticQuery(query);
  const featuredProductLinks = featuredProducts.map((product) => product.content.url);
  const filteredProducts = allProduct.nodes.filter((product) =>
    featuredProductLinks.includes(product.link)
  );

  return (
    <div className="landing-related-products">
      <h2 className="landing-related-products__header">{heading}</h2>

      <div className="landing-related-products__list-container">
        <div className="landing-related-products__list">
          {filteredProducts.slice(0, filteredProducts.length).map((product, index) => {
            const id = `${product.cardTitle}_${index}`;

            return <ProductCard key={id} {...{ ...product }} />;
          })}
        </div>
      </div>
    </div>
  );
};

// graphql query to get all products
export const query = graphql`
  query {
    allProduct {
      nodes {
        link
        productImage {
          ...FragmentGatsbyImage
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        imageFamily {
          ...FragmentGatsbyImage
        }
        linkFamily {
          url
        }
        currentFormat {
          properties {
            label
          }
        }
      }
    }
  }
`;

export default LandingRelatedProducts;
