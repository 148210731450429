import React, { FC } from 'react';
import classnames from 'classnames';

import { ILandingFeaturesComponentProps } from 'components/LandingFeatures/models';
import GatsbyImage from 'components/common/GatsbyImage';
import IconCustom from 'components/common/IconCustom';

import 'components/LandingFeatures/LandingFeatures.scss';

const LandingFeatures: FC<ILandingFeaturesComponentProps> = ({
  image,
  leftFeatures,
  rightFeatures,
  heading,
  additional,
  link,
}) => {
  const middleColumnClasses = classnames('landing-features__middle-column', {
    'landing-features__middle-column--single': !additional,
  });

  return (
    <div className="landing-features">
      <div className="container-fluid">
        <h2 className="lp-heading">{heading}</h2>
        <div className="landing-features__content">
          <div className="landing-features__icons">
            {leftFeatures?.map((item) => (
              <div className="landing-features__feature">
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img
                  className="landing-features__feature-icon"
                  src={item.properties.image?.fallbackUrl}
                  alt={item.properties.image?.altText}
                />
                <div className="landing-features__feature-title">{item.properties.title}</div>
                <div className="landing-features__feature-text">{item.properties.text}</div>
              </div>
            ))}
          </div>

          <div className={middleColumnClasses}>
            <div className="landing-features__product-image">
              <GatsbyImage
                isLazyLoading={false}
                className="home-banner__image-img"
                fluid={image}
                alt="image alt"
                objectPosition="50% 100%"
                useFocalPoint
              />
            </div>

            {additional ? (
              <div
                className="landing-features__additional"
                dangerouslySetInnerHTML={{ __html: additional }}
              />
            ) : null}

            <a
              href={link[0].url}
              target={link[0].target}
              rel="noopener"
              className="gs-btn gs-btn--landing-page"
            >
              {link[0].name}
              <IconCustom icon="chevron-right" />
            </a>
          </div>

          <div className="landing-features__icons">
            {rightFeatures?.map((item) => (
              <div className="landing-features__feature">
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img
                  className="landing-features__feature-icon"
                  src={item.properties.image?.fallbackUrl}
                  alt={item.properties.image?.altText}
                />
                <div className="landing-features__feature-title">{item.properties.title}</div>
                <div className="landing-features__feature-text">{item.properties.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFeatures;
