import React, { FC } from 'react';

import GatsbyImage from 'components/common/GatsbyImage';

import { ILandingImageBannerProps } from './models';

const LandingImageBanner: FC<ILandingImageBannerProps> = ({ bannerImage, bannerImageAlt }) => (
  <div className="landing-image-banner">
    <GatsbyImage className="landing-image-banner__image" fluid={bannerImage} alt={bannerImageAlt} />
  </div>
);

export default LandingImageBanner;
