import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';
import { ILandingHowComponentProps } from 'components/LandingHow/models';

import 'components/LandingHow/LandingHow.scss';

const LandingHow: FC<ILandingHowComponentProps> = ({ heading, subHeading, features, link }) => (
  <div className="landing-how">
    <div className="container-fluid">
      <h2 className="lp-heading">{heading}</h2>
      <DangerouslySetInnerHtml element="h3" html={subHeading} className="lp-subheading" />
      <div className="landing-how__features">
        {features.map((item) => (
          <div className="landing-how__item">
            <div className="landing-how__item-image">
              <GatsbyImage
                isLazyLoading
                fluid={item.properties.image}
                alt={item.properties.image?.altText}
                objectPosition="50% 100%"
                useFocalPoint
                objectFit="contain"
              />
            </div>
            <div className="landing-how__item-title">{item.properties.title}</div>
            <div className="landing-how__item-text">{item.properties.text}</div>
          </div>
        ))}
      </div>
      <a
        href={link[0].url}
        target={link[0].target}
        rel="noopener"
        className="gs-btn gs-btn--landing-page"
      >
        {link[0].name}
        <IconCustom icon="chevron-right" />
      </a>
    </div>
  </div>
);

export default LandingHow;
